/**
 * Component that allows for hiding/showing of password inputs.
 *
 * This component will be a bit of a mega component that combines functionality
 * from higher-order components like ./email and logic from ./input-with-label.
 *
 * 1) It needs to maintain its own state on top of the Input state
 * so that it can switch between two types of inputs
 * 2) It needs to register and change state like a ./password component
 * 3) It needs to reimplement logic from ./input-with-label because it needs
 * to retain the label between the two different inputs.
 * 4) It needs to submit as a password
 */

import { Fragment, PureComponent } from 'react'

import styled from '@emotion/styled'

import { lightTheme } from '@/theme'
import { ErrorIcon } from '@microcomponents/icons/new'
import InputComponent from '@stories/inputs/form-input'
import { Label, FloatLabel } from '@stories/inputs/label'

import { string, func, bool } from 'prop-types'

export class ShowPassword extends PureComponent {
  constructor(props) {
    super(props)

    const MINIMUM_LENGTH = 6
    this.validators = [
      (value) => {
        if (!props.required) return null
        if (!value)
          return (
            <Fragment>
              <ErrorIcon color={lightTheme.colors.danger} />
              Password is required
            </Fragment>
          )
      },
      (value) => {
        if (!value) return null
        return value.length >= MINIMUM_LENGTH ? null : (
          <Fragment>
            <ErrorIcon color={lightTheme.colors.danger} />
            {`Please enter a password with at least ${MINIMUM_LENGTH} characters`}
          </Fragment>
        )
      }
    ]
  }

  static propTypes = {
    // ** Required propTypes ** //
    // Other props not listed here will be checked by Input
    // Name of input. Required for submitting values correctlyw
    name: string.isRequired,
    // Placeholder to use in label
    placeholder: string.isRequired,
    initialValue: string,
    id: string,
    required: bool
  }

  static contextTypes = {
    onChange: func,
    register: func
  }

  state = {
    value: this.props.initialValue || '',
    showPassword: false
  }

  handleChange = (e) => {
    this.context.onChange(this.props.name, e.target.value, this.wrapper.isValid())
    this.setState({ value: e.target.value })
  }

  componentDidMount() {
    this.context.register(this.props.name)
  }

  handleShowingPassword = () => {
    // When a user clicks/taps the eye in mobile, we don't want to lose the keyboard,
    // so automatically focus on the input
    this.wrapper.input.focus()
    this.setState({ showPassword: !this.state.showPassword })
  }

  renderEyecon() {
    return <EyeComponent onClick={this.handleShowingPassword}>{this.state.showPassword ? 'HIDE' : 'SHOW'}</EyeComponent>
  }

  render() {
    const { showPassword } = this.state

    const { placeholder, ...remainingProps } = this.props

    const hasValue = this.wrapper && this.wrapper.value()

    const labelProps = {
      htmlFor: this.props.id || this.props.name,
      float: hasValue
    }

    return (
      <Container>
        {hasValue ? (
          <FloatLabel {...labelProps}>{placeholder}</FloatLabel>
        ) : (
          <Label {...labelProps}>{placeholder}</Label>
        )}
        <Input
          className="fs-exclude"
          {...remainingProps}
          type={showPassword ? 'text' : 'password'}
          onChange={this.handleChange}
          validators={this.validators}
          inputIcon={this.renderEyecon()}
          ref={(wrapper) => {
            this.wrapper = wrapper
          }}
        />
      </Container>
    )
  }
}

const EyeComponent = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  height: 45px;
  margin: 0.5rem 0;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const Container = styled.div`
  position: relative;
`

const Input = styled(InputComponent)`
  padding-right: 60px;
  height: 45px;
  margin: 0.5rem 0;
  width: 100%;

  &::placeholder {
    color: transparent;
  }

  .errorInput & {
    box-shadow: 0 1.5px 1px -1px ${({ theme }) => theme.colors.danger};
  }
`
