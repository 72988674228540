/**
 * Small page content component
 * This component wants to standardize the look of small pages. A small page is defined as a full screen page on mobile and fixed width (390px) on desktop.
 * On mobile the page should take at least the full height while on desktop it shouldn't.
 */

import { ClassNames, css } from '@emotion/react'

import { breakpoint, lightTheme } from '@/theme'

import { InferProps, node, string } from 'prop-types'

const PageContentSmall = (props: InferProps<typeof PageContentSmall.propTypes>) => {
  const contentSmallClass = [contentSmallCss, !!props.className && props.className]

  return (
    <ClassNames>
      {({ css }) => (
        <div className={css(contentSmallClass)} data-e2eid={props['data-e2eid'] || ''}>
          {props.children}
        </div>
      )}
    </ClassNames>
  )
}
PageContentSmall.propTypes = {
  children: node,
  className: string,
  'data-e2eid': string
}

const contentSmallCss = css`
  margin: ${lightTheme.spacing.xl}px auto 0;
  padding: 0 ${lightTheme.spacing.medium}px;
  width: 100%;
  max-width: ${breakpoint.min.sm}px;
`

export default PageContentSmall
